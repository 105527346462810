import Vue from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { firestoreAction } from "vuexfire";

import axios from "@/http/axios";
import { getNested } from "@/store/utils.js";

const getCustomIndicatorDataDefaultState = () => {
  return {
    indicatorValues: {},
    indicatorCurrentLevel: {},
    cumulativeReturnsWithExposure: {},
    outperformanceData: {},
    drawdownsData: {},
    biggestDrawdownsData: {},
    performanceStats: {},
    yearToDateStartDate: {},
    downloadableIndicator: {},
    downloadableEndOfDayExposure: {},
  };
};

const state = {
  customIndicatorData: getCustomIndicatorDataDefaultState(),
  customIndicators: null,
  predefinedCustomIndicators: undefined,
  notifications: [],
};

const getters = {
  getCustomIndicators: (state) => {
    // Here we want to also return the predefined custom indicators,
    // but if any is undefined, it means we have an issue with firestore.
    if (
      state.customIndicators === undefined ||
      state.predefinedCustomIndicators === null
    ) {
      return null;
    }

    if (state.customIndicators === null) {
      return state.predefinedCustomIndicators;
    }

    if (state.predefinedCustomIndicators === undefined) {
      return state.customIndicators;
    }

    // We first return the predefined custom indicators so that they appear first.
    return state.predefinedCustomIndicators.concat(state.customIndicators);
  },
  getCustomIndicator: (_, getters) => (customIndicatorId) => {
    // Get both user-defined custom indicators and predefined custom indicators.
    const customIndicators = getters.getCustomIndicators;
    if (!customIndicators) {
      return customIndicators;
    }

    return customIndicators.find(
      (customIndicator) => customIndicator.id === customIndicatorId
    );
  },
  getCustomIndicatorValues: (state) => (customIndicatorId) => {
    return getNested(
      state.customIndicatorData,
      "indicatorValues",
      customIndicatorId
    );
  },
  getCustomIndicatorCurrentLevel: (state) => (customIndicatorId) => {
    return getNested(
      state.customIndicatorData,
      "indicatorCurrentLevel",
      customIndicatorId
    );
  },
  getCustomIndicatorCumulativeReturnsWithExposure:
    (state) => (customIndicatorId) => {
      return getNested(
        state.customIndicatorData,
        "cumulativeReturnsWithExposure",
        customIndicatorId
      );
    },
  getCustomIndicatorOutperformanceData: (state) => (customIndicatorId) => {
    return getNested(
      state.customIndicatorData,
      "outperformanceData",
      customIndicatorId
    );
  },
  getCustomIndicatorDrawdownsData: (state) => (customIndicatorId) => {
    return getNested(
      state.customIndicatorData,
      "drawdownsData",
      customIndicatorId
    );
  },
  getCustomIndicatorBiggestDrawdownsData: (state) => (customIndicatorId) => {
    return getNested(
      state.customIndicatorData,
      "biggestDrawdownsData",
      customIndicatorId
    );
  },
  getCustomIndicatorPerformanceStats: (state) => (customIndicatorId) => {
    return getNested(
      state.customIndicatorData,
      "performanceStats",
      customIndicatorId
    );
  },
  getYearToDateStartDate: (state) => (customIndicatorId) => {
    return getNested(
      state.customIndicatorData,
      "yearToDateStartDate",
      customIndicatorId
    );
  },
  getDownloadableCustomIndicator: (state) => (customIndicatorId) => {
    return getNested(
      state.customIndicatorData,
      "downloadableIndicator",
      customIndicatorId
    );
  },
  getCustomIndicatorDownloadableEndOfDayExposure:
    (state) => (customIndicatorId) => {
      return getNested(
        state.customIndicatorData,
        "downloadableEndOfDayExposure",
        customIndicatorId
      );
    },
  getNotifications: (state) => {
    return state.notifications;
  },
};

const mutations = {
  RESET_CUSTOM_INDICATOR_DATA_STATE(state) {
    state.customIndicatorData = getCustomIndicatorDataDefaultState();
  },
  FETCH_CUSTOM_INDICATOR_VALUES_SUCCESS: (
    state,
    { customIndicatorId, data }
  ) => {
    Vue.set(state.customIndicatorData.indicatorValues, customIndicatorId, data);
  },
  FETCH_CUSTOM_INDICATOR_CURRENT_LEVEL_SUCCESS: (
    state,
    { customIndicatorId, data }
  ) => {
    Vue.set(
      state.customIndicatorData.indicatorCurrentLevel,
      customIndicatorId,
      data
    );
  },
  FETCH_CUSTOM_INDICATOR_CUMULATIVE_RETURNS_WITH_EXPOSURE_SUCCESS: (
    state,
    { customIndicatorId, data }
  ) => {
    Vue.set(
      state.customIndicatorData.cumulativeReturnsWithExposure,
      customIndicatorId,
      data
    );
  },
  FETCH_CUSTOM_INDICATOR_OUTPERFORMANCE_DATA_SUCCESS: (
    state,
    { customIndicatorId, data }
  ) => {
    Vue.set(
      state.customIndicatorData.outperformanceData,
      customIndicatorId,
      data
    );
  },
  FETCH_CUSTOM_INDICATOR_DRAWDOWNS_DATA_SUCCESS: (
    state,
    { customIndicatorId, data }
  ) => {
    Vue.set(state.customIndicatorData.drawdownsData, customIndicatorId, data);
  },
  FETCH_CUSTOM_INDICATOR_BIGGEST_DRAWDOWNS_DATA_SUCCESS: (
    state,
    { customIndicatorId, data }
  ) => {
    Vue.set(
      state.customIndicatorData.biggestDrawdownsData,
      customIndicatorId,
      data
    );
  },
  FETCH_CUSTOM_INDICATOR_PERFORMANCE_STATS_SUCCESS: (
    state,
    { customIndicatorId, data }
  ) => {
    Vue.set(
      state.customIndicatorData.performanceStats,
      customIndicatorId,
      data
    );
  },
  FETCH_CUSTOM_INDICATOR_YEAR_TO_DATE_START_DATE_SUCCESS: (
    state,
    { customIndicatorId, data }
  ) => {
    Vue.set(
      state.customIndicatorData.yearToDateStartDate,
      customIndicatorId,
      data
    );
  },
  FETCH_CUSTOM_INDICATOR_DOWNLOADABLE_INDICATOR_SUCCESS: (
    state,
    { customIndicatorId, data }
  ) => {
    data?.forEach((el) => (el["date"] = el["date"].slice(0, 10)));
    Vue.set(
      state.customIndicatorData.downloadableIndicator,
      customIndicatorId,
      data
    );
  },
  FETCH_CUSTOM_INDICATOR_DOWNLOADABLE_END_OF_DAY_EXPOSURE_SUCCESS: (
    state,
    { customIndicatorId, data }
  ) => {
    data?.forEach((el) => (el["date"] = el["date"].slice(0, 10)));
    Vue.set(
      state.customIndicatorData.downloadableEndOfDayExposure,
      customIndicatorId,
      data
    );
  },
  FETCH_PREDEFINED_CUSTOM_INDICATORS_SUCCESS: (
    state,
    { predefinedCustomIndicators }
  ) => {
    state.predefinedCustomIndicators = predefinedCustomIndicators;
  },
  FETCH_CUSTOM_INDICATOR_CONFIG_FAILURE: (state) => {
    state.customIndicators = undefined;
  },
  FETCH_PREDEFINED_CUSTOM_INDICATOR_CONFIG_FAILURE: (state) => {
    state.predefinedCustomIndicators = null;
  },
};

const actions = {
  resetCustomIndicatorDataState({ commit }) {
    commit("RESET_CUSTOM_INDICATOR_DATA_STATE");
  },
  runBacktest(
    { commit },
    {
      customIndicatorId,
      benchmarkId,
      benchmarkExchange,
      period,
      currency,
      minInUnderlying,
      maxInUnderlying,
      underlyingInBenchmark,
      statIds,
      underlyingType,
      rebalancingFrequency,
      rebalancingThreshold,
      txCost,
    }
  ) {
    const params = {
      currency: currency,
      benchmark_id: benchmarkId,
      benchmark_exchange: benchmarkExchange,
    };
    if (minInUnderlying != null) {
      params["min_in_underlying"] = minInUnderlying;
    }
    if (maxInUnderlying != null) {
      params["max_in_underlying"] = maxInUnderlying;
    }
    if (underlyingInBenchmark != null) {
      params["underlying_in_bm"] = underlyingInBenchmark;
    }
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (statIds) {
      params["stat_ids"] = statIds;
    }
    if (underlyingType) {
      params["underlying_type"] = underlyingType;
    }
    if (rebalancingFrequency) {
      params["rebalancing_frequency"] = rebalancingFrequency;
    }
    if (rebalancingThreshold != null) {
      params["rebalancing_threshold"] = rebalancingThreshold;
    }
    if (txCost != null) {
      params["tx_cost"] = txCost;
    }
    return axios
      .post(`/api/custom-indicators/${customIndicatorId}/backtest`, params)
      .then((response) => {
        commit("FETCH_CUSTOM_INDICATOR_VALUES_SUCCESS", {
          customIndicatorId,
          data: response.data["indicator"],
        });
        commit("FETCH_CUSTOM_INDICATOR_CURRENT_LEVEL_SUCCESS", {
          customIndicatorId,
          data: response.data["indicator_current_level"],
        });
        commit(
          "FETCH_CUSTOM_INDICATOR_CUMULATIVE_RETURNS_WITH_EXPOSURE_SUCCESS",
          {
            customIndicatorId,
            data: response.data["cumulative_returns_with_exposure"],
          }
        );
        commit("FETCH_CUSTOM_INDICATOR_OUTPERFORMANCE_DATA_SUCCESS", {
          customIndicatorId,
          data: response.data["outperformance_data"],
        });
        commit("FETCH_CUSTOM_INDICATOR_DRAWDOWNS_DATA_SUCCESS", {
          customIndicatorId,
          data: response.data["drawdowns_data"],
        });
        commit("FETCH_CUSTOM_INDICATOR_BIGGEST_DRAWDOWNS_DATA_SUCCESS", {
          customIndicatorId,
          data: response.data["biggest_drawdowns_data"],
        });
        commit("FETCH_CUSTOM_INDICATOR_PERFORMANCE_STATS_SUCCESS", {
          customIndicatorId,
          data: response.data["performance_data"],
        });
        commit("FETCH_CUSTOM_INDICATOR_YEAR_TO_DATE_START_DATE_SUCCESS", {
          customIndicatorId,
          data: response.data["ytd_start_date"],
        });
        commit("FETCH_CUSTOM_INDICATOR_DOWNLOADABLE_INDICATOR_SUCCESS", {
          customIndicatorId,
          data: response.data["downloadable_indicator"],
        });
        commit(
          "FETCH_CUSTOM_INDICATOR_DOWNLOADABLE_END_OF_DAY_EXPOSURE_SUCCESS",
          {
            customIndicatorId,
            data: response.data["downloadable_end_of_day_exposure"],
          }
        );
      })
      .catch((error) => {
        commit("FETCH_CUSTOM_INDICATOR_VALUES_SUCCESS", {
          customIndicatorId,
          data: null,
        });
        commit("FETCH_CUSTOM_INDICATOR_CURRENT_LEVEL_SUCCESS", {
          customIndicatorId,
          data: null,
        });
        commit(
          "FETCH_CUSTOM_INDICATOR_CUMULATIVE_RETURNS_WITH_EXPOSURE_SUCCESS",
          {
            customIndicatorId,
            data: null,
          }
        );
        commit("FETCH_CUSTOM_INDICATOR_OUTPERFORMANCE_DATA_SUCCESS", {
          customIndicatorId,
          data: null,
        });
        commit("FETCH_CUSTOM_INDICATOR_DRAWDOWNS_DATA_SUCCESS", {
          customIndicatorId,
          data: null,
        });
        commit("FETCH_CUSTOM_INDICATOR_BIGGEST_DRAWDOWNS_DATA_SUCCESS", {
          customIndicatorId,
          data: null,
        });
        commit("FETCH_CUSTOM_INDICATOR_PERFORMANCE_STATS_SUCCESS", {
          customIndicatorId,
          data: null,
        });
        commit("FETCH_CUSTOM_INDICATOR_YEAR_TO_DATE_START_DATE_SUCCESS", {
          customIndicatorId,
          data: null,
        });
        commit("FETCH_CUSTOM_INDICATOR_DOWNLOADABLE_INDICATOR_SUCCESS", {
          customIndicatorId,
          data: null,
        });
        commit(
          "FETCH_CUSTOM_INDICATOR_DOWNLOADABLE_END_OF_DAY_EXPOSURE_SUCCESS",
          {
            customIndicatorId,
            data: null,
          }
        );

        // We rethrow the error for sentry to catch it.
        throw error;
      });
  },
  bindCustomIndicatorsRef: firestoreAction(({ commit, bindFirestoreRef }) => {
    // Bind custom indicators for the current user, but also make sure to get
    // the predefined custom indicators.
    const getCustomIndicatorsPromise = () => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        return Promise.resolve();
      }

      return bindFirestoreRef(
        "customIndicators",
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .collection("customIndicators")
      ).catch((error) => {
        // We need to rethrow the error if we hope to catch
        // it in sentry.
        // Note that we only do this if the user is still set,
        // as the most likely case is that we tried to log in the
        // user, it succeeded but we needed to sign out him, and
        // thus the binding promise above fail with a "Missing permission".
        // as it already started to get the link with Firestore
        // while we signed out the user.
        if (firebase.auth().currentUser) {
          commit("FETCH_CUSTOM_INDICATOR_CONFIG_FAILURE");
          throw error;
        }
      });
    };

    const predefinedCustomIndicatorsPromise = firebase
      .firestore()
      .collection("predefinedCustomIndicators")
      .get()
      .then((querySnapshot) => {
        const predefinedCustomIndicators = [];
        querySnapshot.forEach((predefinedCustomIndicatorDoc) => {
          predefinedCustomIndicators.push(predefinedCustomIndicatorDoc.data());
        });
        return commit("FETCH_PREDEFINED_CUSTOM_INDICATORS_SUCCESS", {
          predefinedCustomIndicators,
        });
      })
      .catch((error) => {
        commit("FETCH_PREDEFINED_CUSTOM_INDICATOR_CONFIG_FAILURE");

        // We rethrow the error for sentry to catch it.
        throw error;
      });

    return Promise.all([
      getCustomIndicatorsPromise(),
      predefinedCustomIndicatorsPromise,
    ]);
  }),
  updateCustomIndicator: firestoreAction(
    (_, { customIndicatorId, newCustomIndicator }) => {
      const currentUser = firebase.auth().currentUser;
      return firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .collection("customIndicators")
        .doc(customIndicatorId)
        .set(newCustomIndicator, { merge: true });
    }
  ),
  deleteCustomIndicator: firestoreAction((_, { customIndicatorId }) => {
    const currentUser = firebase.auth().currentUser;
    return Promise.all([
      firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .collection("customIndicators")
        .doc(customIndicatorId)
        .delete(),
      // To delete the notifications linked to a custom indicator,
      // we can't simply delete the document in notification collection,
      // but we need delete all elements in userDefined collection one by
      // one.
      firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .collection("notifications")
        .doc(customIndicatorId)
        .collection("userDefined")
        .get()
        .then((querySnapshot) => {
          return Promise.all(
            querySnapshot.docs.map((doc) => {
              return firebase
                .firestore()
                .collection("users")
                .doc(currentUser.uid)
                .collection("notifications")
                .doc(customIndicatorId)
                .collection("userDefined")
                .doc(doc.id)
                .delete();
            })
          );
        }),
    ]);
  }),
  bindNotifications: firestoreAction(
    ({ bindFirestoreRef }, { indicatorId }) => {
      const currentUser = firebase.auth().currentUser;
      return bindFirestoreRef(
        "notifications",
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .collection("notifications")
          .doc(indicatorId)
          .collection("userDefined")
      );
    }
  ),
  addNotification: firestoreAction((_, { indicatorId, newNotification }) => {
    const currentUser = firebase.auth().currentUser;
    // Whatever the situation, we need to create the indicatorId document
    // otherwise when we will iterate on every user, we won't see indicator
    // that have been created through the "userDefined" collection.
    // Check: https://firebase.google.com/docs/firestore/using-console?authuser=0&hl=en#non-existent_ancestor_documents
    // Note that we don't care about waiting for the result here,
    // the save of the next operation doesn't need this one to achieve,
    // and we only need to eventually create the indicatorId document.
    firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .collection("notifications")
      .doc(indicatorId)
      .set({}, { merge: true });

    // Here we get a new id first, that we will attach to our newNotification
    // object before saving it in firestore.
    const newNotificationRef = firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .collection("notifications")
      .doc(indicatorId)
      .collection("userDefined")
      .doc();

    newNotification["notificationId"] = newNotificationRef.id;

    return newNotificationRef.set(newNotification);
  }),
  updateNotification: firestoreAction(
    (_, { indicatorId, updatedNotification }) => {
      const currentUser = firebase.auth().currentUser;
      return firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .collection("notifications")
        .doc(indicatorId)
        .collection("userDefined")
        .doc(updatedNotification["notificationId"])
        .set(updatedNotification, { merge: true });
    }
  ),
  deleteNotification: firestoreAction((_, { indicatorId, notification }) => {
    const currentUser = firebase.auth().currentUser;
    return firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .collection("notifications")
      .doc(indicatorId)
      .collection("userDefined")
      .doc(notification["notificationId"])
      .delete();
  }),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
