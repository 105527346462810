/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";
import _ from "lodash";
// import progressiveLoader from "./plugins/progressiveLoader";

import { ALQUANT_INDICATORS } from "@/assets/constants/indicators/alquant";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

// Auth
import moduleAuth from "./auth/moduleAuth.js";

// User information
import moduleUserInfo from "./modules/userInfo.js";

// Assets
import moduleAssets from "./modules/assets.js";

// Benchmarks
import moduleBenchmarks from "./modules/benchmarks.js";

// Portfolios
import modulePortfolios from "./modules/portfolios.js";

// Custom Indicators
import moduleCustomIndicators from "./modules/customIndicators.js";

// Indicators' tools
import moduleToolTactical from "./modules/indicators/tools/toolTactical";

class ImportError extends Error {}

/* INDICATORS */

// Create indicators modules
const loadIndicatorModule = (indicatorName) => {
  try {
    return require(`@/store/modules/indicators/${indicatorName}.js`).default;
  } catch (e) {
    throw new ImportError(
      `Unable to import indicator module '${indicatorName}'`
    );
  }
};
const allIndicatorsModules = ALQUANT_INDICATORS.map((indicatorName) => {
  try {
    let moduleIndicator = loadIndicatorModule(indicatorName);
    return [indicatorName, moduleIndicator];
  } catch (err) {
    if (err instanceof ImportError) {
      // Need to deep clone otherwise the different modules will share the same state.
      let moduleIndicator = _.cloneDeep(
        require("@/store/modules/indicators.js").default
      );
      return [indicatorName, moduleIndicator];
    } else {
      throw err;
    }
  }
});
// Add tools if at least one indicator.
if (allIndicatorsModules.length > 0) {
  allIndicatorsModules.push(["toolTactical", moduleToolTactical]);
}
const mapIndicatorsModules = new Map(allIndicatorsModules);

export default new Vuex.Store({
  getters,
  mutations: {
    ...mutations,
    ...vuexfireMutations,
  },
  state,
  actions,
  modules: {
    auth: moduleAuth,
    userInfo: moduleUserInfo,
    assets: moduleAssets,
    benchmarks: moduleBenchmarks,
    portfolios: modulePortfolios,
    customIndicators: moduleCustomIndicators,
    ...Object.fromEntries(mapIndicatorsModules),
  },
  strict: process.env.NODE_ENV !== "production",
  // plugins: [progressiveLoader]
});
