import { extend } from "vee-validate";
import {
  required,
  email,
  min,
  max,
  confirmed,
  min_value,
  max_value,
} from "vee-validate/dist/rules";
import moment from "moment";
import _ from "lodash";

extend("email", {
  ...email,
  message: "The email is not valid.",
});

extend("min_char", {
  ...min,
  message: "The field needs at least {length} characters.",
});

extend("max_char", {
  ...max,
  message: "The field can have no more than {length} characters.",
});

extend("required", {
  ...required,
  message: "This field is required.",
});

// Note that we assume this rule is only used for
// passwords, with the confirm input being just below
// the password input, hence the message we define here.
extend("confirmed", {
  ...confirmed,
  message: "The field doesn't match with previous one.",
});

extend("min_value", {
  ...min_value,
  message: "The value must be greater or equal to {min}.",
});

extend("max_value", {
  ...max_value,
  message: "The value must be smaller or equal to {max}.",
});

extend("date_format", {
  params: ["format"],
  validate: (date, { format }) => {
    return moment(date, format, true).isValid();
  },
  message: "Date must be in the format YYYY-MM-DD.",
});

extend("after_date", {
  params: ["minDate", "included", "format"],
  validate: (date, { minDate, included, format }) => {
    // We only compare dates if they have valid formats.
    // Note that, normally, at this stage the 'date' is
    // valid as we check that first, but in case of we
    // do it again here.
    if (
      !moment(date, format, true).isValid() ||
      !moment(minDate, format, true).isValid()
    ) {
      return true;
    }

    if (included) {
      return date >= minDate;
    }

    return date > minDate;
  },
  message: (_, placeholders) => {
    return `Date must be after ${placeholders.minDate}, ${
      placeholders.included ? "included" : "not included"
    }.`;
  },
});

extend("before_date", {
  params: ["maxDate", "included", "format"],
  validate: (date, { maxDate, included, format }) => {
    // We only compare dates if they have valid formats.
    // Note that, normally, at this stage the 'date' is
    // valid as we check that first, but in case of we
    // do it again here.
    if (
      !moment(date, format, true).isValid() ||
      !moment(maxDate, format, true).isValid()
    ) {
      return true;
    }

    if (included) {
      return date <= maxDate;
    }

    return date < maxDate;
  },
  message: (_, placeholders) => {
    return `Date must be before ${placeholders.maxDate}, ${
      placeholders.included ? "included" : "not included"
    }.`;
  },
});

// To be used with vue-phone-number-input.
extend("phone_number", {
  params: ["payload"],
  validate(_, { payload }) {
    return payload && payload.phoneNumber && payload.isValid;
  },
  message: "This field is not a valid phone number",
});

extend("excluded_assets", {
  params: ["excludedAssets"],
  validate(asset, { excludedAssets }) {
    return (
      !asset ||
      excludedAssets.filter((excludedAsset) => excludedAsset.id === asset.id)
        .length == 1
    );
  },
  message: "You can't have multiple times the same security.",
});

extend("excluded", {
  params: ["excludedValues", "message"],
  validate(value, { excludedValues }) {
    return (
      !value ||
      excludedValues.filter((excludedValue) => _.isEqual(excludedValue, value))
        .length == 1
    );
  },
  message: (_, placeholders) => placeholders.message,
});

extend("at_least_one_defined", {
  params: ["values", "message"],
  validate(_, { values }) {
    return values.some((val) => val != null);
  },
  message: (_, placeholders) => placeholders.message,
});

extend("sum", {
  params: ["expectedValue", "tolerance", "values"],
  validate(_, { expectedValue, tolerance, values }) {
    return (
      Math.abs(
        values.reduce((previous, current) => previous + current, 0) -
          expectedValue
      ) < tolerance
    );
  },
  message: (_, placeholders) => {
    return `The expected sum is ${
      placeholders.expectedValue
    } (current: ${placeholders.values
      .filter((val) => typeof val == "number")
      .reduce((previous, current) => previous + current, 0)
      ?.toFixed(2)}).`;
  },
});
