var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      {
        ref: "card",
        staticClass: "vx-card",
        class: [
          { "overflow-hidden": _vm.tempHidden },
          { "no-shadow": _vm.noShadow },
          { "rounded-none": _vm.noRadius },
          { "card-border": _vm.cardBorder },
          _vm.cardClasses,
        ],
        style: _vm.cardStyles,
      },
      _vm.$listeners
    ),
    [
      _vm.hasHeader
        ? _c("div", { staticClass: "vx-card__header" }, [
            _c("div", { staticClass: "vx-card__title" }, [
              this.$props.title
                ? _c(
                    "h4",
                    { class: _vm.titleClasses, style: _vm.titleStyles },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  )
                : _vm._e(),
              this.$props.subtitle
                ? _c(
                    "h6",
                    { class: _vm.subtitleClasses, style: _vm.subtitleStyles },
                    [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "vx-card__actions" },
              [_vm._t("actions")],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          ref: "content",
          staticClass: "vx-card__collapsible-content vs-con-loading__container",
          class: [
            { collapsed: _vm.isContentCollapsed },
            { "overflow-hidden": _vm.tempHidden },
          ],
          style: _vm.StyleItems,
        },
        [
          _vm._t("no-body"),
          this.$slots.default
            ? _c(
                "div",
                { staticClass: "vx-card__body" },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _vm._t("no-body-bottom"),
          this.$slots.footer
            ? _c(
                "div",
                { staticClass: "vx-card__footer" },
                [_vm._t("footer")],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }